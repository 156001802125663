/* Header styles */
.home-header {
    background-color: #f8f8f8;
    padding: 20px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.home-container {
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex; /* Align nav items in a row */
    justify-content: center; /* Center nav items */
}

nav ul li {
    margin: 0 20px; /* Space out the nav items */
}

nav ul li a {
    text-decoration: none; /* Remove underline from links */
    color: #333; /* Dark text for contrast */
    font-weight: bold; /* Bold font for the links */
}

nav ul li a:hover {
    color: #007bff; /* Color change on hover for interactivity */
}

/* Main content styles */
.home-main {
    text-align: center;
    padding: 40px 20px;
}

.home-main img {
    width: 250px; 
    height: 250px; 
    border-radius: 50%; 
    margin-top: 20px;
    object-fit: cover; 
    border: 5px solid #eee; 
}

.home-main p {
    color: #666; /* Dark grey text */
    font-size: 18px; /* Slightly larger font size */
    line-height: 1.6; /* Space out the lines a bit */
}


/* Footer styles */
.home-footer {
    background-color: #f8f8f8;
    padding: 20px;
    box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
    margin-top: 40px; /* Add some space before the footer */
}

/* Make links in the footer look better */
.home-footer a {
    color: #007bff;
    text-decoration: none;
}

.home-footer a:hover {
    text-decoration: underline;
}

/* Utility classes for margins and padding */
.mt-20 { margin-top: 20px; }
.mb-20 { margin-bottom: 20px; }
.pt-20 { padding-top: 20px; }
.pb-20 { padding-bottom: 20px; }

/* Responsive design for different screen sizes */
@media (max-width: 768px) {
    .home-header h1,
    .home-main h2,
    .home-main p,
    .home-footer p {
        font-size: 90%; /* Slightly smaller font-size on smaller screens */
    }

    nav ul {
        flex-direction: column; /* Stack the navigation items on top of each other */
        margin-top: 20px;
    }

    nav ul li {
        margin: 10px 0; /* Less space between navigation items */
    }
}


.home-footer p {
    color: #333;
}

.introduction-section {
    margin-top: 50px; /* Add space above the introduction section */
}
